import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { isBrowser } from "../utils/auth"
import { sanityConfig } from "../utils/sanity"

import Banner from "../components/banner"
import Layout from "../components/layout"
import PortableText from "../components/portable-text"
import Seo from "../components/seo"
import { useAuth0 } from "@auth0/auth0-react"

export default function EventTemplate({ data }) {
  const { event } = data
  const { isAuthenticated } = useAuth0()

  const featureImage = event.featureImage
    ? getGatsbyImageData(
        event.featureImage.asset.id,
        { maxWidth: 1024 },
        sanityConfig
      )
    : null

  return (
    <Layout>
      <Seo title={event.title} />
      <Banner label="Events">{event.title}</Banner>

      <main className="container mx-auto">
        <div className={`px-3 py-3 lg:py-12 lg:flex items-start`}>
          <button
            id="subNav"
            className="lg:hidden py-1 text-lg font-semibold text-indigo-800 hover:text-indigo-600 flex items-center"
          >
            <span className="pr-2">{event.title}</span>
            <svg
              id="arrowDown"
              className="w-4 mt-1 fill-current"
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m2.5 31.301c0-2.6016 1-5.1016 2.8984-7.1016 3.8984-3.8984 10.301-3.8984 14.199 0l30.402 30.301 30.398-30.398c3.8984-3.8984 10.301-3.8984 14.199 0 3.8984 3.8984 3.8984 10.301 0 14.199l-37.496 37.5c-1.8984 1.8984-4.3984 2.8984-7.1016 2.8984-2.6992 0-5.1992-1.1016-7.1016-2.8984l-37.5-37.402c-1.8984-2-2.8984-4.5977-2.8984-7.0977z" />
            </svg>
            <svg
              id="arrowUp"
              className="hidden w-4 mt-1 fill-current"
              version="1.1"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m97.5 68.699c0 2.6016-1 5.1016-2.8984 7.1016-3.8984 3.8984-10.301 3.8984-14.199 0l-30.402-30.301-30.398 30.301c-3.8984 3.8984-10.301 3.8984-14.199 0-3.8984-3.8984-3.8984-10.301 0-14.199l37.5-37.5c1.8984-1.8984 4.3984-2.8984 7.1016-2.8984 2.6992 0 5.1992 1.1016 7.1016 2.8984l37.5 37.5c1.8945 2 2.8945 4.5977 2.8945 7.0977z" />
            </svg>
          </button>

          <article className="leading-loose w-full max-w-3xl xl:text-lg">
            <h2 className="text-2xl lg:text-4xl font-medium mt-0 mb-8">
              About This Event
            </h2>

            {featureImage && (
              <GatsbyImage className="mb-6 rounded" image={featureImage} />
            )}

            {event.programmePdf?.asset && (
              <div className="block lg:hidden mb-3">
                <a
                  className="font-semibold px-1 lg:px-3 py-2 ml-1 mr-0 bg-indigo-900 text-white hover:bg-gray-900 rounded flex justify-center"
                  href={event.programmePdf.asset.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="mr-2"
                    style={{ width: 24 }}
                  >
                     {" "}
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  View event programme.
                </a>
              </div>
            )}

            <PortableText blocks={event._rawDescription} />

            <div className="block mb-8 lg:hidden">
              {!event.isPastEvent &&
                (!isAuthenticated && event.isRestrictedContent ? (
                  <Link
                    to="/login"
                    className="font-semibold text-center block bg-indigo-900 hover:bg-indigo-700 text-white rounded px-6 py-2"
                    onClick={() => {
                      if (isBrowser) {
                        const redirectUrl = window.location.pathname
                        sessionStorage.setItem("redirectUrl", redirectUrl)
                      }
                    }}
                  >
                    Login to Register
                  </Link>
                ) : (
                  <>
                    {event.bookingUrl ? (
                      <a
                        className="font-semibold px-1 lg:px-3 py-2 ml-1 mr-0 bg-indigo-900 text-white hover:bg-gray-900 rounded block text-center"
                        href={event.bookingUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Register
                      </a>
                    ) : (
                      <p>Tickets Coming Soon</p>
                    )}
                  </>
                ))}
            </div>

            {event.location && (
              <>
                <h2 className="text-2xl lg:text-4xl font-medium mt-12 mb-8">
                  Additional Information
                </h2>
                {/* <EventMap
                eventTitle={event.title}
                position={{
                  lat: event.location.lat,
                  lng: event.location.lng,
                }}
              /> */}
              </>
            )}
          </article>

          {!event.isPastEvent && (
            <aside
              id="sectionMenu"
              className="hidden lg:block lg:sticky self-start top-0 ml-12"
              style={{ width: 300 }}
            >
              <div className="lg:my-3">
                {event.programmePdf?.asset && (
                  <div className="mb-6">
                    <p className="lg:text-xl font-semibold text-gray-800 border-b-4 border-gray-200 mb-2">
                      Event Programme
                    </p>

                    <p className="mb-4">
                      For a more in-depth overview please click the button below
                      to view the event programme.
                    </p>
                    <a
                      className="px-1 lg:px-3 py-2 ml-1 mr-0 bg-indigo-900 text-white hover:bg-gray-900 rounded flex justify-center font-semibold"
                      href={event.programmePdf.asset.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mr-2"
                        style={{ width: 24 }}
                      >
                         {" "}
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      View Programme
                    </a>
                  </div>
                )}

                <p className="lg:text-xl font-semibold text-gray-800 border-b-4 border-gray-200 mb-2">
                  Registration
                </p>
                {!isAuthenticated && event.isRestrictedContent ? (
                  <Link
                    to="/login"
                    className="text-center block bg-indigo-900 hover:bg-indigo-700 text-white rounded px-6 py-2"
                    onClick={() => {
                      if (isBrowser) {
                        const redirectUrl = window.location.pathname
                        sessionStorage.setItem("redirectUrl", redirectUrl)
                      }
                    }}
                  >
                    Login to Register
                  </Link>
                ) : (
                  <>
                    {event.bookingUrl ? (
                      <>
                        <p className="mb-4">
                          Please click the button below to register for this
                          event.
                        </p>
                        <a
                          className="font-semibold px-1 lg:px-3 py-2 ml-1 mr-0 bg-indigo-900 text-white hover:bg-gray-900 rounded block text-center"
                          href={event.bookingUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register
                        </a>
                      </>
                    ) : (
                      <p>Tickets Coming Soon</p>
                    )}
                  </>
                )}
              </div>
            </aside>
          )}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    event: sanityEvent(id: { eq: $id }) {
      bookingUrl
      title
      isRestrictedContent
      isPastEvent
      _rawDescription
      programmePdf {
        asset {
          url
        }
      }
      featureImage {
        asset {
          id
        }
      }
      location {
        lat
        lng
      }
    }
  }
`
